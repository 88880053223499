.button {
    background-color: #0071ce;
    flex: 1;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    min-width: 150px;
    height: 40px;
}

.button:hover {
    background-color: #005a9e;
}

.button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.krogerLogo {
    width: 64px;
    margin-right: 8px;
    object-fit: contain;
}

.accessTokenContainer {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.refreshButton {
    background-color: #0071ce;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.refreshButton:hover {
    background-color: #005a9e;
}